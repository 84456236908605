import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Microdata } from './Microdata';
import { OpenGraph } from './OpenGraph';
import { Twitter } from './Twitter';
import { Favicons } from './Favicons';
import { Base } from './Base';

const DEFAULT_IMAGE =
  'https://ucarecdn.com/2b70a9e7-f4c9-4f81-ab14-0454085e7d5d/uploadcare-file-handling-api.png';
const DEFAULT_TILE_TEMPLATE = '%s | Uploadcare';

export const Seo = ({
  title,
  description,
  url,
  canonicalUrl,
  noindex,
  keywords,
  image = DEFAULT_IMAGE,
  titleTemplate = DEFAULT_TILE_TEMPLATE,
  schema,
}) => {
  return (
    <>
      <Helmet title={title} titleTemplate={titleTemplate} />
      <Base
        lang="en"
        url={url}
        canonicalUrl={canonicalUrl}
        keywords={keywords}
        noindex={noindex}
        description={description}
        image={image}
        schema={schema}
      />
      <Favicons />
      <OpenGraph
        description={description}
        image={image}
        title={title}
        type="article"
        url={url}
        locale="en_US"
        name="Uploadcare"
      />
      <Twitter title={title} image={image} description={description} username="@Uploadcare" />
      <Microdata title={title} image={image} description={description} />
    </>
  );
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  canonicalUrl: PropTypes.string,
  image: PropTypes.string,
  noindex: PropTypes.bool,
  keywords: PropTypes.string,
  titleTemplate: PropTypes.string,
  schema: PropTypes.object,
};
