import React from 'react';
import { Helmet } from 'react-helmet';

export const Microdata = ({ title, description, image }) => (
  <Helmet>
    <meta key="itemprop:name" itemProp="name" content={title} />
    <meta key="itemprop:description" itemProp="description" content={description} />
    <meta key="itemprop:image" itemProp="image" content={image} />
  </Helmet>
);
