import { css } from 'styled-components';
import { withMedia } from '../utils/withMedia';

export const typography = css`
  *,
  html {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: var(--color-light);
    min-height: max-content;
  }

  body {
    font-family: var(--font-sans);
    font-weight: 400;
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--color-dark);
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
    overflow-x: hidden;

    @media (${withMedia('--extra-large')}) {
      font-size: 18px;
      line-height: 32px;
    }
  }

  article,
  aside,
  canvas,
  dd,
  figcaption,
  footer,
  form,
  header,
  hgroup,
  li,
  main,
  nav,
  section,
  thead,
  tfoot,
  tbody {
    margin: 0;
    padding: 0;
  }

  address,
  audio,
  blockquote,
  dl,
  fieldset,
  figure,
  hr,
  ol,
  output,
  p,
  pre,
  ul,
  video {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-headings);
    margin: 0;
  }
`;
