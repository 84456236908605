import React from 'react';
import Helmet from 'react-helmet';

export const Blinkloader = ({ pubkey = 'blinkloader', ...props }) => {
  const script = `
    (function(src, cb) {
      var s = document.createElement('script'); s.setAttribute('src', src);
      s.onload = cb; (document.head || document.body).appendChild(s);
    })('https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js', function() {
      window.Blinkloader.optimize(${JSON.stringify({ pubkey, ...props })});
    })
  `;
  return (
    <Helmet>
      <script>{script}</script>
    </Helmet>
  );
};
