import escape from 'lodash.escape';

/**
 * ES6 String Literals Tag to escape js template with lodash.escape
 *
 * @param {any} strings
 * @param {any} values
 * @returns {string}
 */
export function jsTemplate(strings, ...values) {
  return strings.reduce((total, current, index) => {
    let result = total;

    result += current;
    if (values[index]) {
      result += escape(values[index]);
    }

    return result;
  }, '');
}
