import { css } from 'styled-components';

export const prismjs = css`
  .gatsby-highlight code {
    position: relative;
    display: block;
    overflow: auto;
    width: auto;
    padding: 1.5em 2em;
    color: var(--color-dark);
    background-color: var(--color-lighten);

    /* Atelier-Cave Green */
    & .string,
    & .attr-value,
    & .symbol,
    & .bullet {
      color: #2a9292;
    }

    & .number,
    & .meta,
    & .class-name,
    & .type,
    & .boolean,
    & .parameter {
      color: #aa573c;
    }

    /* Atelier-Cave Red */
    & .variable,
    & .template-variable,
    & .attribute,
    & .tag,
    & .name,
    & .regexp,
    & .link,
    & .selector {
      color: #be4678;
    }

    /* Atelier-Cave Purple */
    & .keyword,
    & .selector-tag {
      color: #955ae7;
    }

    /* Atelier-Cave Blue */
    & .function,
    & .section {
      color: #576ddb;
    }

    /* Atelier-Cave Comment */
    & .comment,
    & .quote {
      color: #655f6d;
    }

    & .cdn-uuid {
      color: #955ae7;
    }

    & .cdn-domain {
      color: #655f6d;
    }

    & .cdn-operations {
      color: #be4678;
    }

    & .cdn-filename {
      color: #655f6d;
    }

    & .cdn-operation-dash {
      color: #655f6d;
    }

    & .cdn-operation-slash {
      color: #655f6d;
    }
  }
`;
