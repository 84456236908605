import { css } from 'styled-components';

export const fonts = css`
  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    src:
      local('Inter-Regular'),
      local('Inter'),
      url('https://ucarecdn.com/4936ae19-1d69-43ff-82c9-bc2e392b3395/InterRegularsubset.woff2')
        format('woff2'),
      url('https://ucarecdn.com/2d30178b-8118-4016-97d6-ba8dd75d6070/InterRegularsubset.woff')
        format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    src:
      local('Inter-Medium'),
      local('Inter'),
      url('https://ucarecdn.com/2fb6a984-43ae-46aa-a521-7fda2f281d12/InterMediumsubset.woff2')
        format('woff2'),
      url('https://ucarecdn.com/00811c88-3b9e-4ad9-85ce-1210d0081441/InterMediumsubset.woff')
        format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    src:
      local('Inter-Bold'),
      local('Inter'),
      url('https://ucarecdn.com/5f976930-5cff-4f25-8fb6-3d67ccd1d2cd/InterBoldsubset.woff2')
        format('woff2'),
      url('https://ucarecdn.com/5d3e1b13-cf70-45bf-8d94-0dc30c3ca50e/InterBoldsubset.woff')
        format('woff');
  }
`;
