import { createGlobalStyle } from 'styled-components';
import { fonts } from './fonts';
import { config } from './config';
import { typography } from './typography';
import { prismjs } from './prismjs';

export const GlobalStyle = createGlobalStyle`
  ${config}
  ${fonts}
  ${typography}
  ${prismjs}
`;
