export const breakpoints = ['768px', '1024px', '1280px'];

export const up = (breakpoint) => `@media (min-width: calc(${breakpoint}))`;

export const theme = {
  breakpoints,
  up,
  tablet: () => up(breakpoints[0]),
  laptop: () => up(breakpoints[1]),
  desktop: () => up(breakpoints[2]),
  isDark: false,
};

export const ThemesEnum = {
  DARK: 'dark',
};
