/* Vendors */
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { ThemeProvider } from 'styled-components';

/* Components */
import { OutlineManager } from '@uc-common/outline-manager';
import { Seo } from '@uc-common/seo';
import { CookieBanner } from '@uc-common/cookie-banner';
import { Blinkloader } from '@uc-common/blinkloader';
import { Uploadcare } from '~/src/layout/Uploadcare';
import { RoutesContext } from '~/src/contexts';
import { GlobalStyle } from '~/src/styles';
import { theme } from '~/src/theme';
import { SupportLinkWatcher } from '../../SupportLinkWatcher/SupportLinkWatcher';

/* Types */

/* Component */

const query = graphql`
  query {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;

export const CommonLayout = ({ className, meta, pathName, children, disableRouter, isDark }) => (
  <StaticQuery
    query={query}
    render={({ allSitePage }) => {
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      const routes = disableRouter ? [] : allSitePage.edges.map(({ node }) => node.path);

      return (
        <ThemeProvider theme={{ ...theme, isDark }}>
          <RoutesContext.Provider value={routes}>
            <GlobalStyle />
            <OutlineManager />
            <SupportLinkWatcher />
            <div className={className}>{children}</div>
            <Seo
              title={
                meta.title || 'Uploadcare — Smart CDN combining cloud storage and upload widget'
              }
              description={
                meta.description ||
                'Uploadcare is a service handling CDN, cloud storage, file uploads & hosting, image upload & processing — all in one secure solution with a powerful file upload widget. Sign up for free!'
              }
              image={meta.image}
              url={process.env.GATSBY_SITE_URL + pathName}
              canonicalUrl={meta.canonicalUrl}
              titleTemplate={meta.customTemplate ? '%s' : '%s | Uploadcare'}
              noindex={meta.noindex}
              schema={meta.schema}
            />
            <Uploadcare pathName={pathName} />
            <CookieBanner />
            <Blinkloader fadeIn />
          </RoutesContext.Provider>
        </ThemeProvider>
      );
    }}
  />
);

/* default props */
CommonLayout.defaultProps = {};
