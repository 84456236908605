import styled, { css, keyframes } from 'styled-components';

const bannerAnimation = keyframes`
  to {
    transform: translateY(0);
  }
`;

export const CookieBannerContainer = styled.div(
  ({ theme: { tablet } }) => css`
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    background-color: #eeeeee;
    width: 100%;
    display: flex;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 99;
    padding: 7px 100px 7px 7px;
    transform: translateY(100%);
    animation: ${bannerAnimation} 0.3s ease-in-out forwards 0.3s;

    ${tablet()} {
      padding-right: 7px;
    }
  `
);

export const CookieBannerText = styled.p(
  ({ theme: { tablet } }) => css`
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    margin: 0;

    ${tablet()} {
      font-size: 13px;
    }
  `
);

export const CookieBannerLink = styled.a`
  text-decoration: underline;
  &,
  &:visited {
    color: #000;
  }
  &:active,
  &:visited:active {
    color: #003ac9;
  }
  &:hover,
  &:focus,
  &:visited:hover,
  &:visited:focus {
    color: #0e54ff;
  }
`;

export const CookieBannerButton = styled.button`
  width: 12px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: #000;
  margin-left: 16px;

  &:hover {
    color: #0e54ff;
  }
`;
