import { css } from 'styled-components';
import { withMedia } from '../utils/withMedia';

export const config = css`
  :root {
    /* Text */
    --font-headings: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Roboto,
      Arial, sans-serif;
    --font-sans: var(--font-headings);
    --font-mono: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas, 'Liberation Mono',
      monospace;
    --font-size: 16px;
    --line-height: 24px;
    --text-padding-bottom: 24px;
    --text-padding-left: 40px;
    --navbar-height: 46px;

    @media (${withMedia('--small')}) {
      --text-padding-left: 24px;
      --navbar-height: 42px;
    }

    @media (${withMedia('--extra-large')}) {
      --text-padding-bottom: 32px;
      --font-size: 18px;
      --line-height: 28px;
      --navbar-height: 58px;
    }

    /* Colors */
    --color-light: #fff;
    --color-lighten: #f6f6f6;
    --color-lighten-grey: #f0f0f0;
    --color-light-grey: #e7e7e7;
    --color-grey: #7b7b7b;
    --color-dark-grey: #404040;
    --color-darker-grey: #40464b;
    --color-darken-grey: #363636;
    --color-dark: #191919;
    --color-light-yellow: #fef33c;
    --color-yellow: #ffdf76;
    --color-dark-yellow: #ffc601;
    --color-light-blue: #52b0ff;
    --color-blue: #238de6;
    --color-dark-blue: #187cce;
    --color-light-purple: #d589ff;
    --color-purple: #b375d4;
    --color-dark-purple: #9f60bc;
    --color-ecru-white: #faf8f3;

    /* Buttons */
    --button-color: #191919;
    --button-outline-color: #52b0ff;
    --button-disabled-color: #c5c4be;

    --button-back-color: #e7e7e7;
    --button-hover-back-color: #f6f6f6;
    --button-active-back-color: #f0f0f0;
    --button-disabled-back-color: #e7e7e7;

    /* Helpers colors */
    --focus-color: rgba(82, 176, 255, 0.6); /* rbg of --color-light-blue */

    /* Messages colors */
    --messages-background-yellow: #fff9d5;
    --messages-background-yellow-hover: #fff6c1;
    --messages-color-yellow: #9b6e56;
    --messages-background-red: #ffebe5;
    --messages-background-red-hover: #ffdacf;
    --messages-color-red: #9c3a3a;
    --messages-background-blue: #e6f8fb;
    --messages-background-blue-hover: #caf1f8;
    --messages-color-blue: #417f9a;
    --messages-background-green: #f0fce8;
    --messages-background-green-hover: #e3fcd2;
    --messages-color-green: #68904c;

    /* Code */
    --code-background-color: var(--color-lighten);

    /* Content */
    --content-max-width: 875px;

    /* Helpers */
    --animation-time: 150ms;
    --hover-transition: background-color var(--animation-time) ease,
      color var(--animation-time) ease, border-color var(--animation-time) ease,
      opacity var(--animation-time) ease;
    --max-z-index: 2147483647;
  }
`;
