import { theme as defaultTheme } from '@uc-common/theme';

export const theme = {
  ...defaultTheme,
  text: {
    heading: {
      'font-family': 'var(--font-heading)',
    },
  },
};
