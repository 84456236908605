import React from 'react';
import { Helmet } from 'react-helmet';

export const Base = ({
  lang,
  url,
  canonicalUrl,
  keywords,
  noindex,
  description,
  image,
  schema,
}) => {
  const canonicalLink = canonicalUrl ?? url;

  return (
    <Helmet>
      <html lang={lang} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <link key="canonical" rel="canonical" href={canonicalLink} />

      {noindex && <meta name="robots" content="noindex, nofollow" />}
      {keywords && <meta key="keywords" name="keywords" content={keywords} />}
      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
};
