import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :focus-visible:not(textarea):not(input) {
    outline-offset: 2px;
    outline: #000000 solid 2px;
  }
`;

export const OutlineManager = () => {
  return <GlobalStyle />;
};
