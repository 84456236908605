import { useEffect } from 'react';

export const SupportLinkWatcher = () => {
  /* Add this class to any elements you want to use to open Drift.
   *
   * Examples:
   * - <a class="js-support-link">Questions? We're here to help!</a>
   * - <button class="js-support-link">Chat now!</button>
   *
   */
  const SUPPORT_LINK_SELECTOR = 'js-support-link';

  useEffect(() => {
    const handleClick = (e) => {
      const element = e.srcElement;

      if (!element) {
        return;
      }

      if (
        typeof element.classList !== 'undefined' &&
        element.classList.contains(SUPPORT_LINK_SELECTOR)
      ) {
        if (window.drift) {
          e.preventDefault();
          window.drift.openChat();
        }
      }
    };

    window.addEventListener('click', handleClick, { capture: true });

    return () => {
      window.removeEventListener('click', handleClick, { capture: true });
    };
  }, []);

  return null;
};
