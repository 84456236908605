import React from 'react';

import {
  CookieBannerContainer,
  CookieBannerLink,
  CookieBannerButton,
  CookieBannerText,
} from './styles/CookieBanner.styles';

import { ReactComponent as IconClose } from './assets/close.svg';
import { useCookie } from '../use-cookie';
import { isBrowser } from '../utils';

export const CookieBanner = ({
  className,
  privacyLink = {
    href: '/about/cookie-policy/',
    target: '_blank',
  },
  name = 'gdpr_consent',
}) => {
  const [cookie, setCookie] = useCookie(name);

  const onConfirm = () => {
    setCookie(name, true, { expires: 365, Secure: true });
  };

  if (cookie || !isBrowser()) return null;

  return (
    <CookieBannerContainer className={className}>
      <CookieBannerText>
        By continuing to use this site you consent to the use of cookies in accordance with&nbsp;
        <CookieBannerLink {...privacyLink}>our cookie policy</CookieBannerLink>.
      </CookieBannerText>
      <CookieBannerButton tabIndex="0" onClick={onConfirm}>
        <IconClose />
      </CookieBannerButton>
    </CookieBannerContainer>
  );
};
