import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { jsTemplate } from '../utils/string-tags';

export const Uploadcare = ({ pathName }) => {
  useEffect(() => {
    const ucFramework = 'Uploadcare-Site';
    const ucPlugin =
      pathName
        .split('/')
        .filter(Boolean)
        .map((chunk) => chunk.charAt(0).toUpperCase() + chunk.slice(1))
        .join('-') || 'Index';

    // set uc-integration after mount for rewrite variable in react-widget
    window.UPLOADCARE_INTEGRATION = `${ucFramework}; ${ucPlugin}`;
  }, [pathName]);

  return (
    <Helmet>
      <script type="text/javascript">
        {jsTemplate`
  UPLOADCARE_LIVE = false;
  UPLOADCARE_MANUAL_START = true;
  UPLOADCARE_PUBLIC_KEY = 'demopublickey';

  UPLOADCARE_SOCIAL_BASE = '${process.env.GATSBY_UPLOADCARE_SOCIAL_BASE}';
  UPLOADCARE_URL_BASE = '${process.env.GATSBY_UPLOADCARE_URL_BASE}';
  UPLOADCARE_CDN_BASE = '${process.env.GATSBY_UPLOADCARE_CDN_BASE}';
  `}
      </script>
    </Helmet>
  );
};
