import React from 'react';
import { Helmet } from 'react-helmet';

export const Favicons = () => (
  <Helmet>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="https://ucarecdn.com/f029badf-c84d-4440-85ae-cf3301355836/"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="https://ucarecdn.com/27856246-9e5a-41a4-8098-f4cf1d006ef1/"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="https://ucarecdn.com/44a4eecf-8d22-4d05-86b9-377c2b963788/"
    />
    <link
      rel="mask-icon"
      href="https://ucarecdn.com/29528e8c-9e27-4ff5-8203-67badc561227/"
      color="#f9d967"
    />
  </Helmet>
);
